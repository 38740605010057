import CompanyVenuesIc from '@/public/icons/ic-dashboard-company-venues.svg';
import OverviewIc from '@/public/icons/ic-dashboard-overview.svg';
import SettingsIc from '@/public/icons/ic-dashboard-settings.svg';
import SupportIc from '@/public/icons/ic-dashboard-support.svg';
import ThinkValIc from '@/public/icons/ic-dashboard-thinkval.svg';
import IcIndonesiaFlag from '@/public/icons/ic-indonesia-flag.svg';
import IcMalaysiaFlag from '@/public/icons/ic-malaysia-flag.svg';
import IcSingaporeFlag from '@/public/icons/ic-singapore-flag.svg';
import IcUkFlag from '@/public/icons/ic-uk-flag.svg';

export const HALAL_FOOD_COUNTRIES = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/halal-food-guide/japan',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/halal-food-guide/hong-kong',
  },
  {
    id: 3,
    name: 'Thailand',
    slug: '/category/halal-food-guide/thailand',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/halal-food-guide/singapore',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/halal-food-guide/south-korea',
  },
  {
    id: 6,
    name: 'Europe',
    slug: '/category/halal-food-guide/europe-halal-food-guide',
  },
];
export const DESTINATIONS_COUNTRIES = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/travel-guide/japan-travel-guide',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/travel-guide/hong-kong-travel-guide',
  },
  {
    id: 3,
    name: 'Thailand',
    slug: '/category/travel-guide/thailand-travel-guide',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/travel-guide/singapore-travel-guide',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/travel-guide/south-korea-travel-tips',
  },
  {
    id: 6,
    name: 'Europe',
    slug: '/category/travel-guide/europe',
  },
];
export const HALAL_FOOD_ALL_CITES_SLUG = '/destination/halal-food-guide';
export const DESTINATIONS_ALL_CITES_SLUG = '/destination/travel-guide';
export const breakPointsCity = [
  { width: 1, itemsToShow: 3 },
  { width: 550, itemsToShow: 5 },
  { width: 768, itemsToShow: 8 },
  { width: 1200, itemsToShow: 8 },
];
export const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
export const PAGE_LIMIT = {
  tags: 40,
  category: 40,
  search: 40,
};
export const FOOTER_DESTINATIONS = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/travel-guide/japan-travel-guide',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/travel-guide/south-korea-travel-tips',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/travel-guide/hong-kong-travel-guide',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/travel-guide/singapore-travel-guide',
  },
];
export const FOOTER_WORK_WITH_US = [
  {
    id: 1,
    name: 'Advertise',
    slug: '/page/advertise',
  },
  {
    id: 2,
    name: 'About Us',
    slug: '/page/about-us',
  },
  {
    id: 3,
    name: 'Join Us',
    slug: '/page/join-us',
  },
  {
    id: 4,
    name: 'HHWT Explorers',
    slug: '/page/contributors-form',
  },
  {
    id: 5,
    name: 'Writer Signup',
    slug: '/page/writer-signup',
  },
];
export const FOOTER_SOCIALS = [
  {
    id: 1,
    name: 'facebook',
    slug: 'https://www.facebook.com/havehalalwilltravel/',
  },
  {
    id: 2,
    name: 'instagram',
    slug: 'https://www.instagram.com/havehalalwilltravel/',
  },
  {
    id: 3,
    name: 'twitter',
    slug: 'https://twitter.com/tweethhwt/',
  },
];
export const FOOTER_HELPFUL_LINKS = [
  {
    id: 1,
    name: 'Halal Food Disclaimer',
    slug: '/page/halal-food-disclaimer',
  },
  {
    id: 2,
    name: 'Modern Muslim Traveler Report',
    slug: '/page/report',
  },
];
export const HOMEPAGE_STRUCTURE = {
  BANNER_ARTICLES: 'Banner',
  LATEST_ARTICLES: 'Latest Articles',
  RECIPES_ARTICLES: 'Recipes',
  DESTINATIONS: 'Destinations',
  HALAL_FOOD_ARTICLES: 'Halal Food Guide',
  ITINERARY_ARTICLES: 'Itinerary',
  ACCOMMODATIONS_ARTICLES: 'Accommodations',
  BUDGET_TRAVEL_ARTICLES: 'Budget Travel',
  HONEY_MOON_ARTICLES: 'Honeymoon',
  BEACH_HOLIDAY_ARTICLES: 'Beach Holidays',
};
export const LANGUAGES_SWITCHER = [
  {
    value: 'en',
    label: 'English',
    icon: IcUkFlag,
    isDisabled: false,
  },
  {
    value: 'id',
    label: 'Bahasa Indonesia',
    icon: IcIndonesiaFlag,
    isDisabled: false,
  },
  {
    value: 'ms',
    label: 'Bahasa Melayu',
    icon: IcMalaysiaFlag,
    isDisabled: false,
  },
];
export const CURRENCY_SWITCHER = [
  {
    id: 1,
    label: 'SGD',
    value: 'sgd',
  },
  {
    id: 2,
    label: 'USD',
    value: 'usd',
  },
  {
    id: 3,
    label: 'VND',
    value: 'vnd',
  },
];
export const ARTICLE_BLOCKS = {
  H2_BLOCK: 'h2_block',
  H3_BLOCK: 'h3_block',
  PARAGRAPH_BLOCK: 'paragraph_block',
  IMAGE_BLOCK: 'image_block',
  SNIPPET_BLOCK: 'venue_Widget',
  INSTAGRAM_EMBED_BLOCK: 'instagram_embed_block',
  MULTIPLE_IMAGES_BLOCK: 'multi_image_block',
  HTML_BLOCK: 'content_HTML_Block',
  ADVERTISEMENT_BLOCK: 'advertisement_block',
  BRAND_BLOCK: 'branded_placement_block',
};
export const HOMEPAGE_BLOCKS = {
  TAG: 'tag',
  CATEGORY: 'category',
  DESTINATIONS: 'destinations',
  ARTICLE: 'posts',
};
export const TRACKING_EVENT_CATEGORIES = {
  IN_ARTICLE_LISTING: 'In-Article Listing Widget',
  BRAND_PLACEMENT_BLOCK: 'Branded Placement Block',
  EXTERNAL_LINK_TRACKER: 'External Link Tracker',
};
export const TRACKING_EVENT_ACTIONS = {
  SCROLL_INTO_VIEW: 1,
  FAVOURITE_BUTTON_CLICKS: 2,
  SHARE_BUTTON_CLICKS: 3,
  SCROLL_THROUGH_IMAGES: 4,
  WIDGET_CLICKS: 5,
  LINK_ARE_CLICKED: 1,
};
export const DESTINATION_HANDBOOKS = {
  HALAL_FOOD_GUIDE: 1,
  BEST_TIME_TO_VISIT: 2,
  SAFETY_ADVISORY: 3,
  LANGUAGE_CUSTOMS: 4,
};
export const DESTINATION_HANDBOOKS_COLORS = {
  green: '#029998',
  yellow: '#BD7100',
  pink: '#AE0092',
  blue: '#0170F1',
};
export const DESTINATION_HANDBOOKS_ICON_COLORS = {
  GREEN: '#03CCCB',
  YELLOW: '#FFAD32',
  PINK: '#F30088',
  BLUE: '#6EB1FF',
};
export const DESTINATION_BLOCKS = {
  ESSENTIAL: 'essential_block',
  HANDBOOK: 'handbook_block',
  ITINERARIES: 'itineraries_block',
  FEATURED_STORIES: 'featured_stories_block',
  PRAYER_TIME: 'praytime_block',
};
export const DESTINATION_HANDBOOKS_LIST_COLORS = [
  {
    icon: '#03CCCB',
    color: '#029998',
    name: 'green',
  },
  {
    icon: '#FFAD32',
    color: '#BD7100',
    name: 'yellow',
  },
  {
    icon: '#F30088',
    color: '#AE0092',
    name: 'pink',
  },
  {
    icon: '#6EB1FF',
    color: '#0170F1',
    name: 'blue',
  },
];
export const REVIEW_FILTER_OPTIONS = [
  { value: '', label: 'Most Recent' },
  { value: '-rating', label: 'Highly Rated' },
  { value: '-upvote', label: 'Most Upvoted' },
];

const YEAR = new Date().getFullYear();
export const DATE_OF_VISITED = [
  { label: 'January' + ' ' + YEAR, value: 1 },
  { label: 'February' + ' ' + YEAR, value: 2 },
  { label: 'March' + ' ' + YEAR, value: 3 },
  { label: 'April' + ' ' + YEAR, value: 4 },
  { label: 'May' + ' ' + YEAR, value: 5 },
  { label: 'June' + ' ' + YEAR, value: 6 },
  { label: 'July' + ' ' + YEAR, value: 7 },
  { label: 'August' + ' ' + YEAR, value: 8 },
  { label: 'September' + ' ' + YEAR, value: 9 },
  { label: 'October' + ' ' + YEAR, value: 10 },
  { label: 'November' + ' ' + YEAR, value: 11 },
  { label: 'December' + ' ' + YEAR, value: 12 },
];
export const DASHBOARD_MENUS = [
  {
    id: 1,
    value: '',
    name: 'Overview',
    icon: OverviewIc,
    slug: '/overview',
  },
  {
    id: 2,
    value: 'thinkval',
    name: 'ThinkVAL Dashboard',
    icon: ThinkValIc,
    slug: '/thinkval',
  },
  {
    id: 3,
    value: 'company-venues',
    name: 'Company & Venues',
    icon: CompanyVenuesIc,
    slug: '/company-venues',
  },
  {
    id: 4,
    value: 'support',
    name: 'Support',
    icon: SupportIc,
    slug: '/support',
  },
  {
    id: 5,
    value: 'settings',
    name: 'Settings',
    icon: SettingsIc,
    slug: '/settings',
  },
];

export const DASHBOARD_MENU_VALUES = {
  OVERVIEW: '',
  THINKVAL: 'thinkval',
  COMPANY_VENUES: 'company-venues',
  SUPPORT: 'support',
  SETTINGS: 'settings',
};
export const CATEGORY = [
  {
    id: 1,
    label: 'Eat',
    value: 'eat',
  },
  {
    id: 2,
    label: 'Buy',
    value: 'buy',
  },
  {
    id: 3,
    label: 'See & Do',
    value: 'see-and-do',
  },
  {
    id: 4,
    label: 'Transport',
    value: 'transport',
  },
  {
    id: 5,
    label: 'Stay',
    value: 'stay',
  },
];

export const DAYS = [
  {
    id: 1,
    value: 0,
    key: 'monday',
    label: 'Monday',
  },
  {
    id: 2,
    value: 1,
    key: 'tuesday',
    label: 'Tuesday',
  },
  {
    id: 3,
    value: 2,
    key: 'wednesday',
    label: 'Wednesday',
  },
  {
    id: 4,
    value: 3,
    key: 'thursday',
    label: 'Thursday',
  },
  {
    id: 5,
    value: 4,
    key: 'friday',
    label: 'Friday',
  },
  {
    id: 6,
    value: 5,
    key: 'saturday',
    label: 'Saturday',
  },
  {
    id: 7,
    value: 6,
    key: 'sunday',
    label: 'Sunday',
  },
];

export const AREA_CODES = [
  {
    id: 1,
    label: 'Singapore',
    value: '+65',
    icon: IcSingaporeFlag,
  },
  {
    id: 2,
    label: 'Malaysia',
    value: '+60',
    icon: IcMalaysiaFlag,
  },
  {
    id: 3,
    label: 'Indonesia',
    value: '+62',
    icon: IcIndonesiaFlag,
  },
];
export const AUTH_METHOD = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  GOOGLE: 'google',
};
export const AUTH_METHOD_LIST = [
  {
    name: 'Phone Number',
    value: AUTH_METHOD.PHONE_NUMBER,
  },
  {
    name: 'Email',
    value: AUTH_METHOD.EMAIL,
  },
];
export const SIGNUP_STEP_VALUES = {
  CREATE_ACCOUNT: 1,
  ONBOARDING: 2,
  FINISH: 3,
};
export const SIGNUP_STEPS = [
  {
    name: 'Create Account',
    value: SIGNUP_STEP_VALUES.CREATE_ACCOUNT,
    slug: 'create-account',
  },
  {
    name: 'Onboarding',
    value: SIGNUP_STEP_VALUES.ONBOARDING,
    slug: 'onboarding',
  },
  {
    name: 'Finish',
    value: SIGNUP_STEP_VALUES.FINISH,
    slug: 'finish',
  },
];
export const PERSONALIZATION_QUESTIONNAIRES = {
  countryTravelBucket: 1,
  kindOfContent: 2,
  intendToTravel: 3,
  usuallyTravelWith: 4,
};

export const PROFILE_METHOD = {
  REVIEWS: 'review',
  FAVOURITES: 'favourite',
};

export const PROFILE_METHOD_LIST = [
  {
    name: 'Reviews',
    value: PROFILE_METHOD.REVIEWS,
  },
  {
    name: 'Favourites',
    value: PROFILE_METHOD.FAVOURITES,
  },
];
export const PROFILE_SORT = [
  {
    id: 1,
    value: 'recent',
    label: 'Recent',
  },
  {
    id: 2,
    value: 'this week',
    label: 'This Week',
  },
  {
    id: 3,
    value: 'from you',
    label: 'From You',
  },
  {
    id: 4,
    value: 'from others',
    label: 'From Others',
  },
];

export const PROFILE_SETTING_METHOD = {
  BASIC: 'basic',
  FAVOURITES: 'preferences',
};

export const PROFILE_SETTING_METHOD_LIST = [
  {
    name: 'Basic information',
    value: PROFILE_SETTING_METHOD.BASIC,
  },
  {
    name: 'Preferences',
    value: PROFILE_SETTING_METHOD.FAVOURITES,
  },
];
export const websiteRegex = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);

export const SAMPLE_ROUTE = {
  ALL_COUNTRY: 'all-country',
  ALL_CITY: 'all-city',
}